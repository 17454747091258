@import url('../../../styles/main');

.user-panel {
  border: 1px solid @primary-color;
  border-radius: 8px;
}

.site-collapse-custom-borderless .ant-collapse-item .ant-collapse-content-box {
  background: white;
  padding: 0;

  .user-panel {
    padding-left: 32px !important;
  }
}

.ant-collapse-borderless > .ant-collapse-item.site-collapse-custom-panel > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  margin: 0.5rem 0;
}

.ant-collapse-borderless > .ant-collapse-item.site-collapse-custom-panel > .ant-collapse-content > .ant-collapse-content-box > .user-panel {
  margin: 0.5rem 0;
  border: 1px solid @primary-color;
  border-radius: 8px;
}