@import '../../../../../../styles/main';

#school-info-card.ant-card {
  .ant-card-body {
    padding: 16px;
  }

  .ant-col {
    text-align: center;

    &:nth-child(1), &:nth-child(2) {
      border-right: 2px solid @disabled-bg;
    }
  }

  .info-header {
    color: black;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .info-body {
    color: @primary-color;
    font-size: 32px;
    font-weight: bold;
  }
}
