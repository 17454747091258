.ant-segmented {
  border-radius: 8px;

  .ant-segmented-group {
    border-radius: inherit;
    overflow: hidden;
    background-color: white;

    .ant-segmented-item {
      background-color: fade(white, 0);
      font-weight: bold;
      font-size: 12px;
      transition: color 0.2s;
      padding: 9px 16px;
    }

    .ant-segmented-item-selected {
      color: white;
      background-color: @fund-education-primary-color;

      :hover {
        color: white;
        transition: color 0.2s;
      }
    }

    .ant-segmented-thumb {
      background-color: @fund-education-primary-color;
    }
  }
}