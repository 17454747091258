@import '../../../../styles/general/colors';
@select-blue-color: #456EFF;

.ant-select.custom-select {
  border-radius: 10px;
  border: 1px solid @select-blue-color;
  width: 100%;

  .ant-select-selector {
    border-radius: 10px !important;
    border: 1px solid @select-blue-color;
  }

  .ant-select-selection-item {
    font-size: 14px;
    font-weight: bold;
    margin: auto 0;
  }
  .ant-select-arrow svg {
    color: @select-blue-color;
  }
  .ant-select-item-option-selected {
    background: @input-selected-background;
    color: @select-blue-color;
  }
  .ant-select-selection-overflow-item {
    margin-right: 0.5em;
    .ant-select-selection-item {
      background: @input-selected-background;
      border-radius: 10px;
      color: @primary-color;
    }
  }
}