@import '../../../styles/main';

.profile-avatar.ant-avatar {
  background-color: @primary-color;
  .ant-avatar-string {
    width: 100%;
    font-weight: bold;

    svg {
      width: 100%;
      height: auto;
      text-align: center;
    }

    text {
      font-size: 150px;
      fill: white;
    }
  }
}