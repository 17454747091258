.container {
  max-width: 100%;
  padding: 0 16px;
  @media screen {
    @media (min-width: 480px) {
      max-width: 480px;
    }
    @media (min-width: 576px) {
      max-width: 576px;
    }
    @media (min-width: 768px) {
      max-width: 768px;
    }
    @media (min-width: 1024px) {
      max-width: 1024px;
    }
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
    @media (min-width: 1536px) {
      max-width: 1536px;
    }
  }
}

.hidden {
  display: none;
}