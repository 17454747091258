@import '../../../../styles/main';

.ant-select-multiple {
  .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;

    span {
      color: @error-color;
    }
  }
}