@import '../../../styles/main';

.select-input.ant-select {
  width: 100%;
  border-radius: 8px;
  box-shadow: @fund-shadow;

  .ant-select-selector {
    border: 0 !important;
    border-radius: 8px !important;
    font-style: normal;
    font-weight: normal;
    min-height: 40px !important;
    line-height: 24px;
    width: 100% !important;
  }

  .ant-select-selection-item {
    font-size: 16px;
    font-weight: bold;
    margin: auto 0;
  }
  .ant-select-arrow svg {
    fill: @primary-color;
  }
  .ant-select-item-option-active {
    background-color: @input-active-background;
  }
  .ant-select-item-option-selected {
    background-color: @input-selected-background;
    color: @primary-color;
  }
  .ant-select-selection-overflow-item{
    margin-right: 0.5em;
    .ant-select-selection-item {
      background-color: @input-selected-background;
      border-radius: 8px;
      color: @primary-color;

      .anticon-close {
        color: @primary-color;
      }
    }
  }
}

.ant-select-single .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
