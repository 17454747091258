@input-placeholder-color: @fund-education-text-color;
@input-border-color: @primary-color;

.ant-form {
  .ant-input,
  .ant-input-number,
  .ant-select-selector,
  .ant-picker {
    border-radius: 10px !important;
    border: 1px solid @input-border-color !important;
    font-style: normal;
    font-weight: normal;
    min-height: 40px !important;
    line-height: 24px;
    width: 100% !important;
  }

  .ant-input-password {

    border-radius: 10px;
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;

    .ant-input{
      border: none !important;
      height: 35px !important;
      min-height: 35px !important;
    }
  }

  .ant-form-item {
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: @fund-education-text-color;
    }
  }

  .ant-input-number {
    overflow: hidden;

    .ant-input-number-input-wrap {
      min-height: 38px !important;
      input {
        min-height: 38px !important;
      }
    }
  }

  .ant-select {
    width: 100%;
    .ant-select-selection-item {
      font-size: 14px;
      font-weight: bold;
      margin: auto 0;
    }
    .ant-select-arrow svg {
      fill: @input-border-color;
    }
    .ant-select-item-option-active {
      background-color: @input-active-background;
    }
    .ant-select-item-option-selected {
      background-color: @input-selected-background;
      color: @input-border-color;
    }
    .ant-select-selection-overflow-item{
      margin-right: 0.5em;
      .ant-select-selection-item {
        background-color: @input-selected-background;
        border-radius: 10px;
        color: @primary-color;

        .anticon-close {
          color: @primary-color;
        }
      }
    }
  }

  .ant-select-single .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  input::placeholder, textarea::placeholder {
    color: @gray-3;
  }
}
