@import '../../../../styles/general/colors';

#donation-thanks {
  text-align: center;

  #confetti-img {
    width: 320px;
  }

  p {
    text-align: left;
  }

  #amount-text {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    :first-child {
      color: @primary-color;
    }
  }
}
