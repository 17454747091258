@import url('../../styles/general/colors');

.information {
  background: @main-gradient;
  padding: 32px 16px;

  &.container {
    @media (min-width: 992px) {
      padding: 32px 5%;
    }
  }
}
