@import '../../../styles/main.less';

.ant-form-item-has-error .custom-image-field {
    border-color: @error-color !important;

    .upload-info-container .anticon {
        color: @error-color;
    }
}

.custom-image-field {
    background-color: @primary-1 !important;
    border: none !important;
    border-radius: 10px !important;
    position: relative;
    z-index: 1;

    .ant-upload-btn {
        padding: 0 !important;
    }

    .upload-info-container {
        &.not-thumbnail {
            padding: 3em;
        }

        .anticon {
            font-size: 15em;
            color: @primary-color;
            transition: color .3s;
            svg {
              width: 100%;
            }
        }

        img.thumbnail {
            max-height: 500px;
            max-width: 100%;
        }
    }
}

.custom-image-field-toolbar {
    &.normal {
        background-color: lighten(@disabled-color, 20%);
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 10px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .ant-btn {
            border-radius: 0 !important;
            margin-top: .5px;
            color: #FFFFFF;

            &:hover, &:focus {
                color: darken(#FFFFFF, 10%);
                background-color: lighten(@disabled-color, 10%);
            }

            &:active {
                color: darken(#FFFFFF, 20%);
                background-color: @disabled-color;
            }

            &:first-child {
                border-top-right-radius: 10px !important;
            }

            &:last-child {
                border-bottom-left-radius: 10px !important;
            }
        }
    }

    &.compact {
        display: inline-block;
    }
}