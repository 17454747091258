@import '../../styles/main';

.signup {
  h1 {
    color: @primary-color;
    text-align: center;
  }

  .tc-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 0;
  }
}