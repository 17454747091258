@import 'animate.css';

.project-card-animation {
  animation: fadeInLeft;
  animation-duration: 1s;
}

.project-card {

  .ant-card-cover {
    padding: 0 !important;
    img {
      border-radius: 8px 8px 0 0 !important;
    }
  }

  .body-container {
    height: 4em;
    overflow: hidden;
    mask-image: linear-gradient(180deg, #000 60%, transparent);
  }

  .goal-message {
    font-size: 12px;
    font-weight: bold;
    line-height: 22px;
  }

  .progress-message {
    color: black;
    margin: 8px 0 16px 0;
  }
}

.project-deleted {
  opacity: 0.3;
}