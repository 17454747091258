.how-works {
  .how-works--title {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;

    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 54px;
    }
  }
  // blog entries
  &.how-works--blog-entries {
    display: flex;
    flex-direction: column;
    margin-bottom: 130px;
    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 16px;
      margin-bottom: 225px;
    }
    @media (min-width: 1200px) {
      gap: 32px;
    }
    .how-works--blog-entry {
      flex: 1;
      height: 450px;
      .how-works--blog-entry__image, .how-works--blog-entry__image img {
        border-radius: 8px;
        height: 250px;
      }
      .how-works--blog-entry__image img {
        object-fit: cover;
        width: 100%;
      }
      .how-works--blog-entry__body {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        overflow: hidden;
      }
    }
  }

  // information
  &.how-works--information {
    display: flex;
    flex-direction: column;

    .how-works--information__section-1 {
      margin-bottom: 32px;
      @media (min-width: 1024px) {
        display: flex;
        flex-direction: row-reverse;
        gap: 24px;
        margin-bottom: 32px;
      }
      .how-works--information__image img {
        object-fit: cover;
        width: 100%;
      }
      .how-works--information__image, .how-works--information__image img  {
        width: 100%;
        @media (min-width: 992px) {
          min-width: 445px;
        }
      }
      .how-works--information__image img  {
        box-shadow: 0 4px 16px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .how-works--information__section-2 {
      display: flex;
      flex-direction: row;
      gap: 24px;
      @media (max-width: 1024px) {
        gap: 16px;
      }
      .how-works--information__image, .how-works--information__image img {
        box-shadow: 0 4px 16px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        flex: 1;
        height: 275px;
        @media (max-width: 992px) {
          display: none;
        }
      }
      .how-works--information__image img {
        object-fit: cover;
        width: 100%;
      }
      .how-works-information__text {
        flex: 2;
      }
      .how-works--information__action {
        display: flex;
        justify-content: center;
        @media (min-width: 992px) {
          justify-content: start;
        }
      }
    }
  }

  .how-works--action {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 145px;
    @media (min-width: 992px) {
      display: none;
    }
  }

  &.how-works--information__text-p1-1 {
    margin-bottom: 24px;
  }
}