.ant-modal {

    min-width: 416px;

    .ant-modal-content {
        border-radius: 10px;
    }

    .ant-modal-confirm-title {
        width: 100%;
        left: 0;
        top: 0;
        padding: 1rem 0;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 38px;
        color: @primary-color;
    }

    .ant-modal-body {
        margin: 1.5rem;
    }

    .ant-form-item {
        label {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: @fund-education-text-color;
        }
    }

    .cross-close-button {
        position: absolute !important;
        top: calc(1.5rem + 32px) !important;
        right: calc(1.5rem + 32px) !important;
        font-size: 1rem !important;
        width: 32px !important;
        height: 32px !important;
        line-height: 3.5rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 8px rgba(96, 97, 112, 0.16);

        :hover {
            color: #000 !important;
        }
    }
}
