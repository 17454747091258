@import '../../../../styles/main';

#project-detail {
  img {
    border-radius: 8px;
    max-width: 100%;
  }

  .project-img {
    width: 100%;
  }

  #donation-disclaimer {
    color: @primary-color;
    font-weight: bold;
    line-height: 15px;
  }

  #institution-card .ant-card-body {
    padding: 16px;
    p {
      margin: 0;
    }
    #institution-name {
      margin-top: 8px;
    }
  }
}