h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 54px;
    word-break: break-word;
}

h2 {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    word-break: break-word;
}

h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    word-break: break-word;
}

h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    word-break: break-word;
}

h5 {
    color: @fund-education-text-color;
    font-weight: 300;
    font-size: 24px;
    line-height: 44px;
    word-break: break-word;
}

.text {
    color: @fund-education-text-color;
    line-height: 24px;

    &._16 {
        font-size: 16px;
    }

    &._14 {
        font-size: 14px;
    }

    &.center {
        text-align: center;
    }
}

.text {
  color: @fund-education-text-color;
  &-body1 {
    color: @fund-education-text-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  &-body2 {
    color: @fund-education-text-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  &-uppercase {
    color: @fund-education-text-color !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  &-sm {
    color: @fund-education-text-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  &-bold {
    color: black;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }
  &-blue-bold {
    color: @primary-color;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }
  &-green-bold {
    color: @fund-education-green;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }
  &-break-word {
    word-break: break-word;
  }
}

.text {
  color: @fund-education-text-color-surface-light;
  &-h1 {
    font-weight: bold;
    font-size: 40px;
  }
  &-h2 {
    font-weight: bold;
    font-size: 32px;
  }
  &-h3 {
    font-weight: bold;
    font-size: 28px;
  }
  &-h4 {
    font-weight: bold;
    font-size: 24px;
  }
  &-body-bold {
    font-weight: bold;
    font-size: 16px;
  }
  &-body-sm-bold {
    font-weight: bold;
    font-size: 12px;
  }
  &-footer-bold {
    color: @fund-education-text-color-surface-dark;
    font-weight: bold;
    font-size: 12px;
  }
}

.text-center {
    text-align: center;
}
.text-justify {
    text-align: justify;
}

.text-primary {
  color: @fund-education-primary-color;
}

.text-green {
  color: @fund-education-green;
}

.text-gray {
  color: @fund-education-text-color;
}

.text-gray-2 {
  color: @gray-2;
}

.text-gray-3 {
  color: @gray-3;
}

.text-black {
  color: black;
}

.text-black-surface {
 color: @fund-education-text-color-surface-dark;
}

.text-white {
  color: white;
}

.text-light {
  color: @fund-education-text-color-surface-light !important;
}

.text-dark {
  color: @fund-education-text-color-surface-dark;
}
