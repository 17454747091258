@import '../../../../styles/main';

#sponsor-home {
  #sponsor-projects-title {
    margin-top: 136px;
    margin-bottom: 16px;
  }

  .switch-container {
    min-height: 24px;
  }

  .project-switch.ant-switch-checked {
    background-color: @fund-education-green;
  }

  .project-switch-label {
    font-size: 12px;
    font-weight: bold;
  }
}
