@import url('../../../../../../styles/general/colors');

.home-steps {
  display: flex;
  flex-direction: column;

  &.steps--title {
    margin-top: 75px;
    @media (min-width: 992px) {
      margin-top: 155px;
    }
  }

  &.steps--container {
    padding: 0 16px;
    gap: 16px;

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-around;
      padding: 0;
    }
  }
}