.blog-entry{
  animation: fadeInLeft 1s;
  flex: 1;
  
  &:hover {
    cursor: pointer;
  }
  
  .blog-entry--image {
    border-radius: 8px;
    max-height: 205px;
    width: 100%;
  }

  .text-truncate-3,
  .text-truncate-4 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    width: 100%;
  }

  .text-truncate-3 {
    -webkit-line-clamp: 3;
  }
  .text-truncate-4 {
    -webkit-line-clamp: 4;
  }
}