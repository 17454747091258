@import '../../../../styles/general/colors';

.blog-entry-page {
  .blog-entry-page--image,
  .blog-entry-page--image img {
    border-radius: 8px;
    width: 100%;
  }
  .blog-entry-page--image {
    display: block;
    min-height: 500px;
    margin-bottom: 24px;
  }
  .blog-entry-page--image img {
    object-fit: cover;
  }

  .blog-entry-page--tag {
    background: @soft-blue;
    border: 1px solid transparent;
    border-radius: 10px;
    color: @primary-color;
    display: inline-block;
    letter-spacing: 2px;
    margin-right: 8px;
    margin-bottom: 32px;
    padding: 3px 8px;
    text-transform: uppercase;
  }
}