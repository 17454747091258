@import 'animate.css';

.menu-bar {
  li {
    font-style: normal;
    font-weight: bold;
  }
}

.menu-bar-row {
  animation: fadeInDown;
  animation-duration: 0.2s;
}