@import '../../../../styles/main';

.title {
  color: @primary-color
}

.join-wizard {
  .ant-steps-item-title {
    font-weight: bold;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: @fund-education-green;

    > .ant-steps-icon {
      color: @fund-education-green;
    }
  }
}

.wizard-content {
  margin-top: 2.5em !important;
}