@import '../../../../styles/main';

#institution-profile {
  .profile-img {
    border-radius: 8px;
    max-width: 100%;
  }

  .create-project-panel {
    img {
      display: block;
      margin: 2em auto;
      max-height: 120px;
      max-width: 120px;
      width: 50%;
    }
    h3 {
      color: @fund-education-primary-color;
      text-align: center;
    }
    p {
      color: @fund-education-text-color;
      font-size: 24px;
      text-align: center;
    }
  }

  .project-container {
    margin: 65px 0;

    .student-title {
      color: @fund-education-primary-color;
      text-align: center;
    }

    .institutional-title {
      color: @fund-education-green;
      text-align: center;
    }

    .download-btn {
      color: @gray-2;
      font-size: 16px;
      font-weight: bold;
      height: 40px;
      line-height: 22px;
    }
  }

  .platform-info {
    background: linear-gradient(180deg, #F2F7FF 0%, #FFFFFF 100%);;
    color: @fund-education-text-color;
    font-size: 16px;
    line-height: 22px;
    padding: 32px 10%;
    span {
      color: black;
      font-weight: bold;
    }
  }
}