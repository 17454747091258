@import '../../../../../../styles/main';

#donation-panel {
  .donation-card {
    background-color: @background-gray;
    border-radius: 8px;

    .donation-container-row {
      margin-top: 8px !important;
      margin-left: 8px !important;
    }

    p {
      margin-bottom: 8px;
    }
  }

  .donation-message {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
  }

  .ant-avatar-string {
    width: 60%;
  }

  .donation-avatar {
    background-color: @gray-6;
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
  }

  .donation-title {
    margin-left: 16px;
    max-width: calc(100% - 56px);
  }
}