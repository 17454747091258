.footer {
  background-color: #FAFBFF;
  .footer__container {
    .ant-row.footer__row {
      margin-left: auto !important;
      margin-right: auto !important;
      @media screen {
        @media (max-width: 480px) {
          margin: 0 !important;
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
      }
    }

    .footer__row {
      .footer__column--links {
        @media screen {
          @media (max-width: 480px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between !important;
          }
        }
        &--link {
          @media screen {
            @media (max-width: 480px) {
              &:nth-child(2) {
                margin: 0 8px !important;
              }
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}