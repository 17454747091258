@import '../../../../../../styles/main';

.report-card {
  .subject-item {
    border: 1px solid @primary-color;
    border-radius: 8px;
    padding: 16px;

    .ant-form-item {
      margin-bottom: 8px;
    }

    .ant-form-item-label label {
      display: flex;
      justify-content: space-between;
    }
  }
}

.report-card-readonly {
  .ant-input, .ant-input-number {
    pointer-events: none;
  }
}