@import '../../../../../../styles/general/colors';

#donation-general-form {
  .ant-checkbox-wrapper {
    align-items: flex-start;
    display: flex;
    span {
      color: @soft-black;
      font-size: 12px;
    }
  }

  .ant-radio-group {
    width: 100%;

    .donation-radio-col {
      display: table;
      table-layout: fixed;
    }

    .ant-radio-button-wrapper {
      border: 1px solid @primary-color;
      border-radius: 8px;
      color: @primary-color;
      display: table-cell;
      font-size: 16px;
      font-weight: bold;
      height: 62px;
      line-height: 22px;
      text-align: center;
      vertical-align: middle;
      width: 100%;

      &.ant-radio-button-wrapper-checked {
        color: white;
      }
    }
  }

  #donation-amount-panel {
    border: 1px solid @disabled-bg;
    border-radius: 8px;

    .amount-input {
      height: 100%;
      margin: 0;

      .ant-form-item-control-input-content {
        display: flex;

        .ant-input-number {
          background-color: @soft-blue;
          border: 1px solid @disabled-bg !important;
          border-radius: 8px !important;
          color: @primary-color;

          input {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }

    #amount_help {
      margin: 10px;
    }

    .amount-label {
      color: @primary-color;
      font-size: 16px;
      font-weight: bold;
      margin-left: 16px;
    }

    .reminder-checkbox {
      height: 100%;
      margin: 0;
      .ant-checkbox-wrapper {
        justify-content: center;
      }
    }
  }

  .currency-disclaimer {
    color: @fund-education-text-color;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
  }

  .donation-message {
    display: flex;

    .ant-form-item {
      margin-left: 8px;
      width: 100%;

      textarea {
        border-color: @disabled-bg !important;
      }
    }
  }

  .disclaimers-container {
    color: @fund-education-text-color;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    line-height: 16px;
    text-align: center;
  }
}

.ant-tooltip-inner#reminderTooltip {
  border-radius: 8px;

  span {
    color: @soft-black;
    font-size: 12px;
    font-weight: bold;
    line-height: 22px;
  }

  p {
    color: @fund-education-text-color;
    font-size: 12px;
    line-height: 16px;
  }
}