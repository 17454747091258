@import '../../../styles/main';

.custom-wysiwyg-field .counter {
  color: @text-color-secondary;
  margin: .5em 1em;
};

.quill > .ql-toolbar:first-child {
  // This style removes the duplicated toolbar in local, but removes the toolbar completely in build
  //display: none !important;
}
