@import '../../../styles/main';

.edit-btn.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @transparent;
  border-color: @gray-2;
  color: @gray-2;
  font-size: 16px;
  font-weight: bold;
  span {
    margin-right: 10px;
  }
}
