@btn-border-radius-base: 10px;

@mix-light-percent: 15%;
@mix-dark-percent: 15%;

//noinspection CssUnknownProperty,LessResolvedByNameOnly
@colors: {success: @success-color; warning: @warning-color};

each(@colors, {
    button.btn-@{key} {
        background-color: @value;
        border-color: shade(@value, @mix-dark-percent);

        &:hover, &:focus {
            background-color: tint(@value, @mix-light-percent);
            border-color: shade(@value, @mix-dark-percent);
        }

        &:active {
            background-color: shade(@value, @mix-dark-percent);
            border-color: shade(@value, @mix-dark-percent);
        }
    }
});

button.ant-btn.ant-btn-primary {
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  height: 42px;
}

button.btn-text-custom {
  background: white;
  border: 1px solid white;
  cursor: pointer;
  font-weight: 400;
  padding: 0;
  text-decoration: underline;
  &.btn-text-custom-primary {
    color: @primary-color;
    text-decoration-color: @primary-color;
  }
  &.btn-text-custom-secondary {
    color: @gray-2;
    text-decoration-color: @gray-2;
  }
}
