@import '../../../../styles/main';
@import 'animate.css';

#create-project-form {
  animation: fadeInLeft;
  animation-duration: 0.2s;

  .category-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .add-btn {
    display: flex !important;
    align-items: center !important;
    padding: 20px !important;

    span {
      margin-right: 10px;
    }
  }

  .search-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    border-color: @primary-color !important;
    color: @primary-color !important;
    font-weight: bold !important;

    span {
      margin-left: 10px;
    }
  }

  .category-card {
    animation: fadeInLeft;
    animation-duration: 0.2s;

    .category-content {
      border: 1px solid @disabled-bg;
      border-radius: 8px;
      padding: 16px;
    }
  }
}