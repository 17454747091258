#app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  .content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 60%;
    }

    .anticon {
      margin-top: 2em;
      color: #FFFF;

      svg {
        font-size: 64px;
      }
    }
  }
}