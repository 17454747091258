.ant-table {
    @table-radius: 10px;
    background: none;

    .ant-table-container {
        border-top-left-radius: @table-radius;
        border-top-right-radius: @table-radius;

        filter: drop-shadow(0px 4px 8px rgba(96, 97, 112, 0.16));
    }

    thead.ant-table-thead > tr > th {
        background-color: @primary-2;

        color: @primary-color;
        font-weight: bold;
        font-size: 16px;

        &:first-child {
            border-top-left-radius: @table-radius !important;
        }

        &:last-child {
            border-top-right-radius: @table-radius !important;
        }

        &::before {
            background-color: @primary-color !important;
        }
    }

    tbody.ant-table-tbody {
        background-color: #FFFF;

        > tr.ant-table-row {
            &:hover > td {
                background-color: inherit;
            }

            &:nth-child(even) {
                background-color: #FAFBFF;
            }

            &:last-child > td {
                &:first-child {
                    border-bottom-left-radius: @table-radius !important;
                }

                &:last-child {
                    border-bottom-right-radius: @table-radius !important;
                }
            }
        }
    }
}
