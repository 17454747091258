@import url('../../../../../../styles/general/colors');

.platform-count {
  &.container {
    margin-bottom: 136px;
    @media (min-width: 992px) {
      margin-bottom: 136px;
      margin-top: 136px;
    }
  }
  &.platform-count__item .platform-count__item--title {
    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 32px;
    }
  }
  &.platform-count__item .platform-count__item--number {
    @media (min-width: 992px) {
      font-size: 96px;
      line-height: 116px;
    }
  }
  .platform-count__divider {
    background: @disabled-bg;
    width: 3px;
  }
}