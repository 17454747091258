@import '../../../styles/main';

.outlined-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-color: @primary-color !important;
  border-radius: 8px;
  color: @primary-color !important;
  font-size: 16px !important;
  font-weight: bold !important;
  padding: 18px !important;

  &:hover {
    background: @primary-color !important;
    color: white !important;
  }

  * {
    margin: 5px;
  }
}
