@import '../../../styles/main';

.category-progress {
  .progress-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
  }
  .progress-footer {
    color: @fund-education-text-color;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
  }
}