@banner-min-height: 480px;
@banner-margin-bottom: 300px;

.home-banner {
  background-image: url('../../../../../../assets/public/banner-xs.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-bottom: calc(@banner-margin-bottom + 50%);
  max-height: 500px;
  min-height: @banner-min-height;
  padding: 0 16px;
  width: 100%;

  @media (min-width: 768px) {
    background-image: url('../../../../../../assets/public/banner.jpg');
    margin-bottom: calc(@banner-margin-bottom + 30%);
  }
  @media (min-width: 992px) {
    background-image: url('../../../../../../assets/public/banner.jpg');
    margin-bottom: calc(@banner-margin-bottom + 10%);
  }

  .home-banner--body__container {
    padding: 80px 0 0 0;
    position: relative;
    .home-banner--body__title, .home-banner--body__subtitle {
      @media (min-width: 768px) {
        width: 50%;
      }
    }
    .home-banner--body__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      @media (min-width: 768px) {
        font-size: 32px;
        line-height: 44px;
      }
    }
    .home-banner--body__subtitle {
      font-size: 16px;
      line-height: 24px;
      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 44px;
      }
    }
    .home-banner--body__card {
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 16px 0 rgba(96, 97, 112, 0.16);
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      left: 0;
      right: 0;
      min-height: 450px;
      position: absolute;
      top: calc(@banner-min-height - 40%);
      width: 100%;

      @media (min-width: 768px) {
        top: calc(@banner-min-height - 20%);
        width: 70%;
      }
      @media (min-width: 992px) {
        flex-direction: row;
        min-height: 200px;
      }

      .home-banner--body__card--video,
      .home-banner--body__card--video video {
        border-radius: 8px 8px 0 0;
        width: 100%;

        @media (min-width: 992px) {
          border-radius: 8px 0 0 8px;
          flex: 2;
        }
      }
      .home-banner--body__card--video video[poster] {
        object-fit: cover;
        @media (min-width: 992px) {
          border-radius: 8px 0 0 8px;
        }
      }
      .home-banner--body__card--text {
        border-radius: 0 0 8px 8px;
        padding: 16px;

        @media (min-width: 992px) {
          flex: 3;
        }
      }
    }
  }
}