@import './../../../styles/main';

.ant-input-search.search-input {
  border-radius: 8px;
  box-shadow: @fund-shadow;

  .ant-input-affix-wrapper {
    border: 0;
    border-radius: 8px !important;
    padding: 8px 16px;

    .ant-input {
      font-size: 16px;
    }
  }

  .ant-input-suffix {
    color: @fund-education-primary-color;
  }

  .ant-input-group-addon {
    display: none;
  }
}