@import '../../../../../../styles/main';

#score-summary {
  .score-header {
    font-size: 16px;
    color: @fund-education-text-color;
  }

  .score-body {
    font-size: 16px;
  }

  .score-footer {
    color: @primary-color;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
}