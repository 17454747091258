@fund-education-primary-color: #5B8DEF;
@fund-education-text-color: #6C6C6C;
@fund-education-text-color-surface-light: #8E8E93;
@fund-education-text-color-surface-dark: #313131;
@fund-education-green: #2FCE80;

@primary-color: @fund-education-primary-color;
@success-color: #39D98A;
@error-color: #FF5C5C;
@warning-color: #FFD512;

@soft-blue: #F2F7FF;
@soft-black: @fund-education-text-color-surface-dark;

@disabled-bg: #E5E5EA;
@disabled-color: #8E8E93;

@background-gray: #FAFBFF;
@gray-2: #AEAEB2;
@gray-3: #C7C7CC;
@gray-6: #F2F2F7;

@input-selected-background: #E5F0FF;
@input-active-background: #E4E4EF;

@menu-selected: #BDCBE1;
@main-gradient: linear-gradient(@soft-blue, white);
@back-gradient: linear-gradient(180deg, #2079C1 0%, #71BFFF 100%);

@soft-blue: #F2F6FF;

@transparent: fade(white, 0%);

@box-shadow-color: #60617029;

@fund-shadow: 0 4px 16px rgba(96, 97, 112, 0.16);

:root {
  --fund-education-primary-color: @fund-education-primary-color;
  --fund-education-green: @fund-education-green;
  --error: @error-color;
  --gray: @gray-2;
  --disabled-bg: @disabled-bg;
}