@import '../../../styles/main';

.outlined-item {
  margin-bottom: 16px;

  .description {
    border: 1px solid @primary-color;
    border-radius: 8px;
    color: @primary-color;
    font-weight: bold;
    font-size: 16px;
    padding: 12px;
  }

  .actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
