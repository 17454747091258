@import url('../../../../styles/main');

.ant-collapse {
  background: white;
}

.site-collapse-custom-borderless {
  overflow: hidden;
  background-color: white;
  .ant-collapse-item {
    border-bottom: 0 !important;
  }
}

.site-collapse-custom-borderless .ant-collapse-header {
  border: 1px solid @primary-color;
  border-radius: 8px !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}