@import '../../../../../../styles/general/colors';

#donation-credit-card-form {
  #amount-text {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .support-tooltip {
      margin-left: 10px;
    }

    :first-child {
      color: @primary-color;
    }
  }

   .credit-card-container {
     border: 1px solid @disabled-bg;
     border-radius: 8px;
     margin-right: 8px;
     height: 40px;
     width: 68px;

     display: inline-flex;
     justify-content: center;
     align-items: center;

     img {
       max-height: 26px;
     }
   }
}