.ant-layout {
  .ant-layout-header {
    background: rgb(255, 255, 255);
    padding: 0 20px;
    box-shadow: 0 4px 16px rgba(96, 97, 112, 0.16);
  }
}

.auth-nav-bar {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  max-width: 1200px;

  &__logo {
    align-items: center;
    display: flex;
    height: 100%;
    margin: auto 0;
  }
  &__menu {
    > ul.ant-menu {
      @media screen {
        @media (max-width: 992px) {
          background: white;
          border-bottom: 0;
        }
      }
      > li.ant-menu-item {
        padding: 0 20px;
        @media screen {
          @media (max-width: 992px) {
            background: white;
            padding: 0;
          }
        }
        > span.ant-menu-title-content {
          display: block;
          @media screen {
            @media (max-width: 992px) {
              display: none;
            }
          }
        }
      }
      > li.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal {
        @media screen {
          @media (max-width: 992px) {
            position: relative !important;
          }
        }
      }
    }
  }
  &__menu-actions {
    &--login.ant-btn {
      border-radius: 0;
      border-left: 1px solid #E5E5EA;
    }
    > * {
      margin: 0 .2em;
    }

    > .ant-btn {
      color: #2689C2;
    }

    .question-icon {
      color: #FFFFFF;
      font-size: 25px;
    }
  }

  .ant-menu-horizontal > .ant-menu-item button {
    color: rgba(0, 0, 0, 0.85) !important;

    :hover {
      color: #5B8DEF;
    }
  }
}