@import '../../../../styles/general/colors';

@banner-min-height: 500px;

#home-public-page-component {
  //sets the margin to the bottom of the content
  margin: 2%;

  .app-logo {
    width: 100%;
    margin: 5em auto 1.5em;
  }

  .app-start-btn {
    margin-bottom: 20%;
  }
}

.home-background {
  background: @main-gradient no-repeat;
  background-size: 100% calc(@banner-min-height + 5%);
  @media (min-width: 992px) {
    background-size: 100% calc(@banner-min-height + 15%);
  }
}

.projects {
  .projects--students, .projects--institutional {
    margin-bottom: 100px;
    @media (min-width: 992px) {
      margin-bottom: 140px;
    }
  }
}

.home--how-it-works {
  background: url('../../../../assets/public/how-it-works/background-how-it-works-xs.png') no-repeat;
  background-size: 100%;
  margin-bottom: 10em;
  padding-top: 156px;

  @media (min-width: 992px) {
    background: url('../../../../assets/public/how-it-works/background-how-it-works.png') no-repeat;
    background-size: 100% 50%;
    padding-top: 91px;
  }
}

.home--steps-to-register {
  margin-bottom: 136px;
}
