@import '../../../../../../styles/main';

#project-general-details {
  margin-bottom: 12px;

  .edit-project-btn {
    margin-bottom: 40px;
  }

  h2 {
    color: @primary-color;
  }

  #summary {
    color: @fund-education-text-color;
    font-size: 24px;
  }

  #project-description {
    margin-top: 32px;
  }

  .download-btn {
    border-color: @error-color !important;
    color: @error-color !important;

    &:hover {
      background: @error-color !important;
      color: white !important;
    }
  }
}