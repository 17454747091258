@import '../../styles/main';

.blog-entries-viewer {
  .ant-card-body {
    padding: 16px !important;

    h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .ant-card-cover {
    display: flex;
    justify-content: center;
    padding: 16px !important;

    img {
      max-width: 400px;
      max-height: 400px;
      border-radius: 8px !important;
    }
  }

  .body-container {
    height: 6em;
    overflow: hidden;
    mask-image: linear-gradient(180deg, #000 60%, transparent);
  }

  .action-icon {
    color: @primary-color !important;
  }
}
