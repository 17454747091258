@import '../../../../styles/main';

.wizard-form {
  .wizard-step-description {
    color: @fund-education-text-color;
    margin: 24px 0;
  }

  .ant-form-item-label label {
    color: @fund-education-text-color !important;
    font-weight: bold;
  }

  .document-btn {
    display: inline-flex !important;
    width: calc(100% - 56px) !important;
  }

  .not-uploaded {
    pointer-events: none;
  }

  .document-upload .ant-upload {
    margin-top: 1.5em;
    width: 100%;
    button {
      width: 100%;
    }
  }
}
