//noinspection CssUnknownTarget
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import '~antd/dist/antd.less';

// antd-css-utilities
@import '~antd-css-utilities/utility.min.css';

@import './general/index';

* {
    font-family: 'Inter', sans-serif;
}