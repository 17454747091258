.project-title-image {
  position: relative;
  text-align: center;
  filter: drop-shadow(0px 4px 16px rgba(96, 97, 112, 0.16));

  img {
    border-radius: 8px;
    width: 100%;
  }

  .project-title {
    position: absolute;
    bottom: 16px;
    left: 24px;

    h3 {
      color: white;
    }
  }
}